import React from 'react'
import { config } from 'src/config/config'

export enum RedirectType {
  Success = '_redirect',
  Error = '_error',
}

interface Props {
  type: RedirectType
  page: string
}

/**
 * Configures the redirect after form submission.
 * See https://documentation.formspark.io/customization/redirection.html
 */
export const RedirectSetting: React.FC<Props> = (props: Props) => {
  return (
    <input type="hidden" name={props.type} value={`${config.baseUrl}/${props.page}`}/>
  )
}
