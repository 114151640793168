export const config = {
  baseUrl: process.env.GATSBY_BASE_URL,
  formspark: {
    // See https://dashboard.formspark.io/
    submitUrl: process.env.GATSBY_FORM_SUBMIT_URL,

    // Configured in formspark settings -> Custom honeypot
    honeypotName: 'accept',
  },
}
