import React from 'react'
import { Honeypot } from 'src/components/formspark/honeypot'
import { RedirectSetting, RedirectType } from 'src/components/formspark/redirect-setting'
import { Wrapper } from 'src/components/layout/wrapper'
import { Link } from 'src/components/link'
import { config } from 'src/config/config'

export default () => {
  return (
    <Wrapper subtitle='Kontakt'>
      <h1>Kontakt</h1>

      <form action={config.formspark.submitUrl} target="_self">
        <RedirectSetting type={RedirectType.Success} page='kontakt-erfolgreich'/>
        <RedirectSetting type={RedirectType.Error} page='kontakt-fehlgeschlagen'/>

        <label className="input-group">
          <span>Name*</span>
          <input type="text" name="name" required/>
        </label>

        <label className="input-group">
          <span>E-Mail Adresse*</span>
          <input type="email" name="email" required/>
        </label>

        <label className="input-group">
          <span>Telefon</span>
          <input type="text" name="phone"/>
        </label>

        {/* place honeypot in the middle of the form to make it hard for bots to detect it */}
        <Honeypot/>

        <label className="input-group">
          <span>Nachricht*</span>
          <textarea name="message" id="" cols={30} rows={10} required/>
        </label>

        <div className="input-group-cb">
          <label>
            <input type="checkbox" required/> <span>Ich akzeptiere die <Link target="_blank" to="/datenschutz">allgemeine Datenschutzerklärung</Link>*</span>
          </label>
        </div>

        <p className="font-extralight"><b>Hinweis:</b> Felder, die mit * bezeichnet sind, sind Pflichtfelder.</p>

        <button type="submit">Anfrage senden</button>
      </form>
    </Wrapper>
  )
}
