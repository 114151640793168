import React from 'react'
import { config } from 'src/config/config'

/**
 * Is used for spam protection instead of using a captcha.
 * See https://documentation.formspark.io/setup/spam-protection.html#honeypot
 */
export const Honeypot: React.FC = () => (
  <label className="hp-accept">
    <input
      type="checkbox"
      name={config.formspark.honeypotName}
    />
    Akzeptiert
  </label>
)